import React from "react";

export default function HeroSection() {
  return (
    <div className="container w-full mx-auto px-[70px] py-[100px] ">
      <div className="w-full mx-auto lg:gap-0">
        <div className="lg:flex lg:items-center">
          <div className="md:pb-3 lg:-mr-4 ">
            <h1 className="md:max-w-[693px] max-w-[300px] font-semibold  md:leading-[67px]  flex items-start md:items-center flex-col md:flex-row">
              <span className="text-[35px] md:text-[64px] bg-clip-text text-transparent  bg-gradient-to-r from-blue-500 via-cyan-400 to-green-400 inline-block pt-1.5 md:pt-0">
                Members & News
              </span>
            </h1>
            <div className="max-w-[815px] text-[#0D283E]">
              <h2 className="pt-[30px] pb-10 font-medium md:font-normal text-[18px] md:text-[20px] ">
                The Fetch.ai Foundation is a hub for innovators shaping the
                future of AI, Web3, blockchain, and beyond. We invite
                journalists and tech enthusiasts to explore the latest AI
                developments. As a member, you&#39;ll gain access to exclusive
                insights, announcements, and expert discussions. Connect with us
                to be at the forefront of the technological revolution and share
                in the narrative of tomorrow&#39;s advancements.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

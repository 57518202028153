import React from "react";
import HeroSection from "../components/members/HeroSection";
import PressReleases from "../components/members/PressReleases";
import FoundationSpeakers from "../components/members/FoundationSpeakers";
import Layout from "../components/layout";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";
import NewsLaterModal from "../components/modal/NewsLaterModal";

function Members() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <div>
      <Layout headerClass="relative">
        <div ref={bannerRef} className="lg:h-[557px]">
          <HeroSection />
        </div>
        <PressReleases />
        <FoundationSpeakers />
        <NewsLaterModal />
      </Layout>
    </div>
  );
}
export default Members;

import React from "react";
import { Spokedata } from "./constant";
import { useNewsLaterContext } from "../../context/NewsLaterContext";

export default function FoundationSpeakers() {

  return (
    <>
      <section className="bg-white">
        <div
          id="Speakers"
          className="mx-auto container flex flex-col items-center justify-center px-[12px] py-[40px] md:py-[100px]"
        >
          <h2 className=" font-bold text-[46px]   text-[#0D283E] text-center">
            Foundation{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
              {" "}
              Speakers
            </span>
          </h2>
          <div className=" relative md:pt-[60px] grid gap-[20px] md:grid-cols-2 pt-[50px] ">
            {Spokedata?.map((spokesperson, index) => (
              <div
                key={index}
                className=" heroCards w-full h-auto px-[1.5px]  !rounded-[14px] "
              >
                <div
                  style={{
                    boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                  }}
                  className="bg-white h-full w-full rounded-3xl p-[30px]"
                >
                  <div
                    className="cards rounded-[16px] flex flex-col gap-[20px]"
                    key={index}
                  >
                    <div className="flex gap-[23px] md:flex-row ">
                      <img
                        src={spokesperson.profileLink}
                        alt="image"
                        className="rounded-full w-[60.13px] h-[60.13px]"
                      />
                      <div className="flex flex-col gap-1 text-[#0D283E]  tracking-[2.4px]">
                        <p className="text-[22.77px] font-medium ">
                          {spokesperson.name}
                        </p>
                        <p className="text-[16px] md:text-[16px] font-semibold  uppercase">
                          {spokesperson.position}
                        </p>
                        <p className="text-[16px] md:text-[16px] font-semibold  uppercase ">
                          {spokesperson.company}
                        </p>
                      </div>
                    </div>
                    <p className="text-[14px] text-[#0D283E]">
                      {spokesperson.content1}
                    </p>
                    <p className="text-[14px] text-[#0D283E]">
                      {spokesperson.content2}
                    </p>
                    <p className="text-[14px] text-[#0D283E]">
                      {spokesperson.content3}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
